<template>
  <div>
    <Header />
    <div class="bg-mid-grey mt-5">
      <div class="grid-container">
        <div class="grid-x grid-padding-x pt-5">
          <div class="cell small-12 medium-2 pt-5 pb-5">
            <h1 class="page-header">Patient Numbers</h1>
          </div>
          <div v-if="indications.includes('wamd')" class="cell small-12 medium-2 pt-5 pb-5">
            <button v-if="selectedIndication === 'wamd'" class="button keep-case expanded blue-active">wAMD</button>
            <button v-else @click="selectIndication('wamd')" class="button keep-case expanded blue-ghost">wAMD</button>
          </div>
          <div v-if="indications.includes('dme')" class="cell small-12 medium-2 pt-5 pb-5">
            <button v-if="selectedIndication === 'dme'" class="button expanded blue-active">DME</button>
            <button v-else @click="selectIndication('dme')" class="button expanded blue-ghost">DME</button>
          </div>
          <div v-if="indications.includes('pdr')" class="cell small-12 medium-2 pt-5 pb-5">
            <button v-if="selectedIndication === 'pdr'" class="button expanded blue-active">PDR</button>
            <button v-else @click="selectIndication('pdr')" class="button expanded blue-ghost">PDR</button>
          </div>
          <div v-if="indications.includes('rvo')" class="cell small-12 medium-2 pt-5 pb-5">
            <button v-if="selectedIndication === 'rvo'" class="button expanded blue-active">RVO</button>
            <button v-else @click="selectIndication('rvo')" class="button expanded blue-ghost">RVO</button>
          </div>
          <div v-if="indications.includes('cnv')" class="cell small-12 medium-2 pt-5 pb-5">
            <button v-if="selectedIndication === 'cnv'" class="button expanded blue-active">CNV</button>
            <button v-else @click="selectIndication('cnv')" class="button expanded blue-ghost">CNV</button>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-light-grey">
      <div class="grid-container">
        <div class="grid-x grid-padding-x pt-5">
          <div class="cell small-12 medium-2 medium-offset-10">
            <h6 class="text-center">Input manually</h6>
          </div>
        </div>
        <div class="grid-x grid-padding-x pt-5">
          <div class="cell small-2 medium-8">
            <h6 class="mb-0">Population</h6>
          </div>
          <div class="cell small-2 medium-2">
            <number v-number="thousands" class="fixed text-center mb-0" disabled type="text" v-model="population"></number>
          </div>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="cell small-12">
            <hr class="short wide orange-line mt-2 mb-2" />
          </div>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="cell small-2 medium-8">
            <h6 v-if="selectedIndication === 'wamd'" class="mb-0">Incidence of wAMD<sup>8,9</sup></h6>
            <h6 v-if="selectedIndication === 'dme'" class="mb-0">Incidence of DME<sup>10,11*</sup></h6>
            <h6 v-if="selectedIndication === 'pdr'" class="mb-0">Incidence of PDR<sup>1,10,11*</sup></h6>
            <h6 v-if="selectedIndication === 'rvo'" class="mb-0">Incidence of RVO<sup>14</sup></h6>
            <h6 v-if="selectedIndication === 'cnv'" class="mb-0">Incidence of CNV<sup>1,15*</sup></h6>
          </div>
          <div class="cell small-2 medium-2">
            <number v-number="percentage" v-if="selectedIndication === 'wamd'" class="default text-center mb-0" type="text" v-model="incidenceOfWamd"></number>
            <number v-number="percentage" v-if="selectedIndication === 'dme'" class="default text-center mb-0" type="text" v-model="incidenceOfDme"></number>
            <number v-number="percentage" v-if="selectedIndication === 'pdr'" class="default text-center mb-0" type="text" v-model="incidenceOfPdr"></number>
            <number v-number="percentage" v-if="selectedIndication === 'rvo'" class="default text-center mb-0" type="text" v-model="incidenceOfRvo"></number>
            <number v-number="percentage" v-if="selectedIndication === 'cnv'" class="default text-center mb-0" type="text" v-model="incidenceOfCnv"></number>
          </div>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="cell small-12">
            <hr class="short wide orange-line mt-2 mb-2" />
          </div>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="cell small-2 medium-8">
            <h6 v-if="selectedIndication === 'wamd'" class="mb-0">Prevalence of wAMD<sup>8,9</sup></h6>
            <h6 v-if="selectedIndication === 'dme'" class="mb-0">Prevalence of DME<sup>1,10,11*</sup></h6>
            <h6 v-if="selectedIndication === 'pdr'" class="mb-0">Prevalence of PDR<sup>12,13†</sup></h6>
            <h6 v-if="selectedIndication === 'rvo'" class="mb-0">Prevalence of RVO<sup>12,14*</sup></h6>
            <h6 v-if="selectedIndication === 'cnv'" class="mb-0">Prevalence of CNV<sup>1,15*</sup></h6>
          </div>
          <div class="cell small-2 medium-2">
            <number v-number="percentage" v-if="selectedIndication === 'wamd'" class="default text-center mb-0" type="text" v-model="prevalenceOfWamd"></number>
            <number v-number="percentage" v-if="selectedIndication === 'dme'" class="default text-center mb-0" type="text" v-model="prevalenceOfDme"></number>
            <number v-number="percentage" v-if="selectedIndication === 'pdr'" class="default text-center mb-0" type="text" v-model="prevalenceOfPdr"></number>
            <number v-number="percentage" v-if="selectedIndication === 'rvo'" class="default text-center mb-0" type="text" v-model="prevalenceOfRvo"></number>
            <number v-number="percentage" v-if="selectedIndication === 'cnv'" class="default text-center mb-0" type="text" v-model="prevalenceOfCnv"></number>
          </div>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="cell small-12">
            <hr class="short wide orange-line mt-2 mb-2" />
          </div>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="cell small-2 medium-8">
            <h6 v-if="selectedIndication === 'wamd'" class="mb-0">Number of new patients per annum with wAMD</h6>
            <h6 v-if="selectedIndication === 'dme'" class="mb-0">Number of new patients per annum with DME</h6>
            <h6 v-if="selectedIndication === 'pdr'" class="mb-0">Number of new patients per annum with PDR</h6>
            <h6 v-if="selectedIndication === 'rvo'" class="mb-0">Number of new patients per annum with RVO</h6>
            <h6 v-if="selectedIndication === 'cnv'" class="mb-0">Number of new patients per annum with CNV</h6>
          </div>
          <div class="cell small-2 medium-2">
            <number
              v-number="thousands"
              v-if="selectedIndication === 'wamd'" class="fixed text-center mb-0" disabled type="text"
              :value="Math.round(population * (incidenceOfWamd / 100))"></number>
            <number
              v-number="thousands"
              v-if="selectedIndication === 'dme'" class="fixed text-center mb-0" disabled type="text"
              :value="Math.round(population * (incidenceOfDme / 100))"></number>
            <number
              v-number="thousands"
              v-if="selectedIndication === 'pdr'" class="fixed text-center mb-0" disabled type="text"
              :value="Math.round(population * (incidenceOfPdr / 100))"></number>
            <number
              v-number="thousands"
              v-if="selectedIndication === 'rvo'" class="fixed text-center mb-0" disabled type="text"
              :value="Math.round(population * (incidenceOfRvo / 100))"></number>
            <number
              v-number="thousands"
              v-if="selectedIndication === 'cnv'" class="fixed text-center mb-0" disabled type="text"
              :value="Math.round(population * (incidenceOfCnv / 100))"></number>
          </div>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="cell small-12">
            <hr class="short wide orange-line mt-2 mb-2" />
          </div>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="cell small-2 medium-8">
            <h6 v-if="selectedIndication === 'wamd'" class="mb-0">Number of patients in the population with wAMD</h6>
            <h6 v-if="selectedIndication === 'dme'" class="mb-0">Number of patients in the population with DME</h6>
            <h6 v-if="selectedIndication === 'pdr'" class="mb-0">Number of patients in the population with PDR</h6>
            <h6 v-if="selectedIndication === 'rvo'" class="mb-0">Number of patients in the population with RVO</h6>
            <h6 v-if="selectedIndication === 'cnv'" class="mb-0">Number of patients in the population with CNV</h6>
          </div>
          <div class="cell small-2 medium-2">
            <number
              v-number="thousands"
              v-if="selectedIndication === 'wamd'" class="fixed text-center mb-0" disabled type="text"
              :value="Math.round(population * (prevalenceOfWamd / 100))"></number>
            <number
              v-number="thousands"
              v-if="selectedIndication === 'dme'" class="fixed text-center mb-0" disabled type="text"
              :value="Math.round(population * (prevalenceOfDme / 100))"></number>
            <number
              v-number="thousands"
              v-if="selectedIndication === 'pdr'" class="fixed text-center mb-0" disabled type="text"
              :value="Math.round(population * (prevalenceOfPdr / 100))"></number>
            <number
              v-number="thousands"
              v-if="selectedIndication === 'rvo'" class="fixed text-center mb-0" disabled type="text"
              :value="Math.round(population * (prevalenceOfRvo / 100))"></number>
            <number
              v-number="thousands"
              v-if="selectedIndication === 'cnv'" class="fixed text-center mb-0" disabled type="text"
              :value="Math.round(population * (prevalenceOfCnv / 100))"></number>
          </div>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="cell small-12">
            <hr class="short wide orange-line mt-2 mb-2" />
          </div>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="cell small-2 medium-8">
            <h6 class="mb-0">Proportion of patients drug treated (%)</h6>
          </div>
          <div class="cell small-2 medium-2">
            <number v-number="percentage" v-if="selectedIndication === 'wamd'" class="empty text-center mb-0" type="text" v-model="proportionOfPatientsDrugTreatedWamd"></number>
            <number v-number="percentage" v-if="selectedIndication === 'dme'" class="empty text-center mb-0" type="text" v-model="proportionOfPatientsDrugTreatedDme"></number>
            <number v-number="percentage" v-if="selectedIndication === 'pdr'" class="empty text-center mb-0" type="text" v-model="proportionOfPatientsDrugTreatedPdr"></number>
            <number v-number="percentage" v-if="selectedIndication === 'rvo'" class="empty text-center mb-0" type="text" v-model="proportionOfPatientsDrugTreatedRvo"></number>
            <number v-number="percentage" v-if="selectedIndication === 'cnv'" class="empty text-center mb-0" type="text" v-model="proportionOfPatientsDrugTreatedCnv"></number>
          </div>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="cell small-12">
            <hr class="short wide orange-line mt-2 mb-2" />
          </div>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="cell small-2 medium-8">
            <h6 class="mb-0">Proportion of drug treated patients receiving ranibizumab (%)</h6>
          </div>
          <div class="cell small-2 medium-2">
            <number
              v-number="percentage"
              v-if="selectedIndication === 'wamd'" class="empty text-center mb-0" type="text"
              v-model="proportionOfDrugTreatedPatientsReceivingRanibizumabWamd"></number>
            <number
              v-number="percentage"
              v-if="selectedIndication === 'dme'" class="empty text-center mb-0" type="text"
              v-model="proportionOfDrugTreatedPatientsReceivingRanibizumabDme"></number>
            <number
              v-number="percentage"
              v-if="selectedIndication === 'pdr'" class="empty text-center mb-0" type="text"
              v-model="proportionOfDrugTreatedPatientsReceivingRanibizumabPdr"></number>
            <number
              v-number="percentage"
              v-if="selectedIndication === 'rvo'" class="empty text-center mb-0" type="text"
              v-model="proportionOfDrugTreatedPatientsReceivingRanibizumabRvo"></number>
            <number
              v-number="percentage"
              v-if="selectedIndication === 'cnv'" class="empty text-center mb-0" type="text"
              v-model="proportionOfDrugTreatedPatientsReceivingRanibizumabCnv"></number>
          </div>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="cell small-12">
            <hr class="short wide orange-line mt-2 mb-2" />
          </div>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="cell small-2 medium-8">
            <h6 class="mb-0">Number of new patients treated per annum with ranibizumab</h6>
          </div>
          <div class="cell small-2 medium-2">
            <number v-number="thousands" v-if="selectedIndication === 'wamd'" class="fixed text-center mb-0" disabled type="text" :value="newTreatedRaniWamd"></number>
            <number v-number="thousands" v-if="selectedIndication === 'dme'" class="fixed text-center mb-0" disabled type="text" :value="newTreatedRaniDme"></number>
            <number v-number="thousands" v-if="selectedIndication === 'pdr'" class="fixed text-center mb-0" disabled type="text" :value="newTreatedRaniPdr"></number>
            <number v-number="thousands" v-if="selectedIndication === 'rvo'" class="fixed text-center mb-0" disabled type="text" :value="newTreatedRaniRvo"></number>
            <number v-number="thousands" v-if="selectedIndication === 'cnv'" class="fixed text-center mb-0" disabled type="text" :value="newTreatedRaniCnv"></number>
          </div>
          <div class="cell small-2 medium-2">
            <number v-number="thousands" v-if="selectedIndication === 'wamd'" class="empty text-center mb-0" type="text" v-model="newTreatedRaniWamdOverride"></number>
            <number v-number="thousands" v-if="selectedIndication === 'dme'" class="empty text-center mb-0" type="text" v-model="newTreatedRaniDmeOverride"></number>
            <number v-number="thousands" v-if="selectedIndication === 'pdr'" class="empty text-center mb-0" type="text" v-model="newTreatedRaniPdrOverride"></number>
            <number v-number="thousands" v-if="selectedIndication === 'rvo'" class="empty text-center mb-0" type="text" v-model="newTreatedRaniRvoOverride"></number>
            <number v-number="thousands" v-if="selectedIndication === 'cnv'" class="empty text-center mb-0" type="text" v-model="newTreatedRaniCnvOverride"></number>
          </div>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="cell small-12">
            <hr class="short wide orange-line mt-2 mb-2" />
          </div>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="cell small-2 medium-8">
            <h6 class="mb-0">Number of patients currently treated per annum with ranibizumab</h6>
          </div>
          <div class="cell small-2 medium-2">
            <number v-number="thousands" v-if="selectedIndication === 'wamd'" class="fixed text-center mb-0" disabled type="text" :value="currentTreatedRaniWamd"></number>
            <number v-number="thousands" v-if="selectedIndication === 'dme'" class="fixed text-center mb-0" disabled type="text" :value="currentTreatedRaniDme"></number>
            <number v-number="thousands" v-if="selectedIndication === 'pdr'" class="fixed text-center mb-0" disabled type="text" :value="currentTreatedRaniPdr"></number>
            <number v-number="thousands" v-if="selectedIndication === 'rvo'" class="fixed text-center mb-0" disabled type="text" :value="currentTreatedRaniRvo"></number>
            <number v-number="thousands" v-if="selectedIndication === 'cnv'" class="fixed text-center mb-0" disabled type="text" :value="currentTreatedRaniCnv"></number>
          </div>
          <div class="cell small-2 medium-2">
            <number v-number="thousands" v-if="selectedIndication === 'wamd'" class="empty text-center mb-0" type="text" v-model="currentTreatedRaniWamdOverride"></number>
            <number v-number="thousands" v-if="selectedIndication === 'dme'" class="empty text-center mb-0" type="text" v-model="currentTreatedRaniDmeOverride"></number>
            <number v-number="thousands" v-if="selectedIndication === 'pdr'" class="empty text-center mb-0" type="text" v-model="currentTreatedRaniPdrOverride"></number>
            <number v-number="thousands" v-if="selectedIndication === 'rvo'" class="empty text-center mb-0" type="text" v-model="currentTreatedRaniRvoOverride"></number>
            <number v-number="thousands" v-if="selectedIndication === 'cnv'" class="empty text-center mb-0" type="text" v-model="currentTreatedRaniCnvOverride"></number>
          </div>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="cell small-12">
            <hr class="short wide orange-line mt-2 mb-2" />
          </div>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="cell small-2 medium-8">
            <h6 class="mb-0">Average number of eyes affected</h6>
          </div>
          <div class="cell small-2 medium-2">
            <select v-if="selectedIndication === 'wamd'" v-model="averageNumberOfEyesAffectedWamd" class="default text-center">
              <option value="1">1.0</option>
              <option value="1.1">1.1</option>
              <option value="1.2">1.2</option>
              <option value="1.3">1.3</option>
              <option value="1.4">1.4</option>
              <option value="1.5">1.5</option>
              <option value="1.6">1.6</option>
              <option value="1.7">1.7</option>
              <option value="1.8">1.8</option>
              <option value="1.9">1.9</option>
              <option value="2">2.0</option>
            </select>
            <select v-if="selectedIndication === 'dme'" v-model="averageNumberOfEyesAffectedDme" class="default text-center">
              <option value="1">1.0</option>
              <option value="1.1">1.1</option>
              <option value="1.2">1.2</option>
              <option value="1.3">1.3</option>
              <option value="1.4">1.4</option>
              <option value="1.5">1.5</option>
              <option value="1.6">1.6</option>
              <option value="1.7">1.7</option>
              <option value="1.8">1.8</option>
              <option value="1.9">1.9</option>
              <option value="2">2.0</option>
            </select>
            <select v-if="selectedIndication === 'pdr'" v-model="averageNumberOfEyesAffectedPdr" class="default text-center">
              <option value="1">1.0</option>
              <option value="1.1">1.1</option>
              <option value="1.2">1.2</option>
              <option value="1.3">1.3</option>
              <option value="1.4">1.4</option>
              <option value="1.5">1.5</option>
              <option value="1.6">1.6</option>
              <option value="1.7">1.7</option>
              <option value="1.8">1.8</option>
              <option value="1.9">1.9</option>
              <option value="2">2.0</option>
            </select>
            <select v-if="selectedIndication === 'rvo'" v-model="averageNumberOfEyesAffectedRvo" class="default text-center">
              <option value="1">1.0</option>
              <option value="1.1">1.1</option>
              <option value="1.2">1.2</option>
              <option value="1.3">1.3</option>
              <option value="1.4">1.4</option>
              <option value="1.5">1.5</option>
              <option value="1.6">1.6</option>
              <option value="1.7">1.7</option>
              <option value="1.8">1.8</option>
              <option value="1.9">1.9</option>
              <option value="2">2.0</option>
            </select>
            <select v-if="selectedIndication === 'cnv'" v-model="averageNumberOfEyesAffectedCnv" class="default text-center">
              <option value="1">1.0</option>
              <option value="1.1">1.1</option>
              <option value="1.2">1.2</option>
              <option value="1.3">1.3</option>
              <option value="1.4">1.4</option>
              <option value="1.5">1.5</option>
              <option value="1.6">1.6</option>
              <option value="1.7">1.7</option>
              <option value="1.8">1.8</option>
              <option value="1.9">1.9</option>
              <option value="2">2.0</option>
            </select>
          </div>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="cell small-12">
            <hr class="short wide orange-line mt-2" />
          </div>
        </div>
        <div class="grid-x grid-padding-x pt-10">
          <div class="cell small-12">
            <p class="compressed text-small float-left pt-1">
              <span class="text-small" v-if="selectedIndication === 'dme'">
                *References used for incidence and prevalence are based on the population in England. It has been assumed that the same rates apply in the Devolved Nations.<br />
              </span>
              <span class="text-small" v-if="selectedIndication === 'pdr'">
                *References used for incidence are based on the population in England. It has been assumed that the same rates apply in the Devolved Nations.<br />
              </span>
              <span class="text-small" v-if="selectedIndication === 'rvo'">
                *Based on global population data extrapolated to the UK<br />
              </span>
              <span class="text-small" v-if="selectedIndication === 'cnv'">
                *References used for incidence and prevalence are based on the population in England. It has been assumed that the same rates apply in the Devolved Nations.<br />
              </span>
              <span class="text-small" v-if="selectedIndication === 'pdr'">
                †Based on global population data extrapolated to the UK<br />
              </span>
              The number of patients currently on treatment is a calculation based on general population x prevalence. For the purposes of the model these are assumed to be patients eligible for SWITCHING.<br />
            </p>
            <button @click="goToShare" class="button float-right">Next</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import { number } from '@coders-tm/vue-number-format';

export default {
  name: 'PatientNumbers',
  components: {
    Header,
    number,
  },
  data() {
    return {
      selectedIndication: null,
      newTreatedRaniWamdOverride: null,
      currentTreatedRaniWamdOverride: null,
      newTreatedRaniDmeOverride: null,
      currentTreatedRaniDmeOverride: null,
      newTreatedRaniPdrOverride: null,
      currentTreatedRaniPdrOverride: null,
      newTreatedRaniRvoOverride: null,
      currentTreatedRaniRvoOverride: null,
      newTreatedRaniCnvOverride: null,
      currentTreatedRaniCnvOverride: null,
      thousands: {
        separator: ',',
        precision: 2,
        masked: true,
      },
      percentage: {
        precision: 3,
        masked: true,
        suffix: '%',
      },
    };
  },
  computed: {
    drugs() { return this.$store.state.drugs; },
    hash() { return this.$store.state.hash; },
    indications() { return this.$store.state.indications; },
    population() { return this.$store.state.population; },
    patientTypes() { return this.$store.state.patientTypes; },
    incidenceOfWamd: {
      get() { return this.$store.state.incidenceOfWamd; },
      set(value) { this.$store.commit('updateIncidenceOfWamd', value); },
    },
    prevalenceOfWamd: {
      get() { return this.$store.state.prevalenceOfWamd; },
      set(value) { this.$store.commit('updatePrevalenceOfWamd', value); },
    },
    incidenceOfDme: {
      get() { return this.$store.state.incidenceOfDme; },
      set(value) { this.$store.commit('updateIncidenceOfDme', value); },
    },
    prevalenceOfDme: {
      get() { return this.$store.state.prevalenceOfDme; },
      set(value) { this.$store.commit('updatePrevalenceOfDme', value); },
    },
    incidenceOfPdr: {
      get() { return this.$store.state.incidenceOfPdr; },
      set(value) { this.$store.commit('updateIncidenceOfPdr', value); },
    },
    prevalenceOfPdr: {
      get() { return this.$store.state.prevalenceOfPdr; },
      set(value) { this.$store.commit('updatePrevalenceOfPdr', value); },
    },
    incidenceOfRvo: {
      get() { return this.$store.state.incidenceOfRvo; },
      set(value) { this.$store.commit('updateIncidenceOfRvo', value); },
    },
    prevalenceOfRvo: {
      get() { return this.$store.state.prevalenceOfRvo; },
      set(value) { this.$store.commit('updatePrevalenceOfRvo', value); },
    },
    incidenceOfCnv: {
      get() { return this.$store.state.incidenceOfCnv; },
      set(value) { this.$store.commit('updateIncidenceOfCnv', value); },
    },
    prevalenceOfCnv: {
      get() { return this.$store.state.prevalenceOfCnv; },
      set(value) { this.$store.commit('updatePrevalenceOfCnv', value); },
    },
    proportionOfPatientsDrugTreatedWamd: {
      get() { return this.$store.state.proportionOfPatientsDrugTreatedWamd; },
      set(value) { this.$store.commit('updateProportionOfPatientsDrugTreatedWamd', value); },
    },
    proportionOfDrugTreatedPatientsReceivingRanibizumabWamd: {
      get() { return this.$store.state.proportionOfDrugTreatedPatientsReceivingRanibizumabWamd; },
      set(value) { this.$store.commit('updateProportionOfDrugTreatedPatientsReceivingRanibizumabWamd', value); },
    },
    proportionOfPatientsDrugTreatedDme: {
      get() { return this.$store.state.proportionOfPatientsDrugTreatedDme; },
      set(value) { this.$store.commit('updateProportionOfPatientsDrugTreatedDme', value); },
    },
    proportionOfDrugTreatedPatientsReceivingRanibizumabDme: {
      get() { return this.$store.state.proportionOfDrugTreatedPatientsReceivingRanibizumabDme; },
      set(value) { this.$store.commit('updateProportionOfDrugTreatedPatientsReceivingRanibizumabDme', value); },
    },
    proportionOfPatientsDrugTreatedPdr: {
      get() { return this.$store.state.proportionOfPatientsDrugTreatedPdr; },
      set(value) { this.$store.commit('updateProportionOfPatientsDrugTreatedPdr', value); },
    },
    proportionOfDrugTreatedPatientsReceivingRanibizumabPdr: {
      get() { return this.$store.state.proportionOfDrugTreatedPatientsReceivingRanibizumabPdr; },
      set(value) { this.$store.commit('updateProportionOfDrugTreatedPatientsReceivingRanibizumabPdr', value); },
    },
    proportionOfPatientsDrugTreatedRvo: {
      get() { return this.$store.state.proportionOfPatientsDrugTreatedRvo; },
      set(value) { this.$store.commit('updateProportionOfPatientsDrugTreatedRvo', value); },
    },
    proportionOfDrugTreatedPatientsReceivingRanibizumabRvo: {
      get() { return this.$store.state.proportionOfDrugTreatedPatientsReceivingRanibizumabRvo; },
      set(value) { this.$store.commit('updateProportionOfDrugTreatedPatientsReceivingRanibizumabRvo', value); },
    },
    proportionOfPatientsDrugTreatedCnv: {
      get() { return this.$store.state.proportionOfPatientsDrugTreatedCnv; },
      set(value) { this.$store.commit('updateProportionOfPatientsDrugTreatedCnv', value); },
    },
    proportionOfDrugTreatedPatientsReceivingRanibizumabCnv: {
      get() { return this.$store.state.proportionOfDrugTreatedPatientsReceivingRanibizumabCnv; },
      set(value) { this.$store.commit('updateProportionOfDrugTreatedPatientsReceivingRanibizumabCnv', value); },
    },
    averageNumberOfEyesAffectedWamd: {
      get() { return this.$store.state.averageNumberOfEyesAffectedWamd; },
      set(value) { this.$store.commit('updateAverageNumberOfEyesAffectedWamd', value); },
    },
    averageNumberOfEyesAffectedDme: {
      get() { return this.$store.state.averageNumberOfEyesAffectedDme; },
      set(value) { this.$store.commit('updateAverageNumberOfEyesAffectedDme', value); },
    },
    averageNumberOfEyesAffectedPdr: {
      get() { return this.$store.state.averageNumberOfEyesAffectedPdr; },
      set(value) { this.$store.commit('updateAverageNumberOfEyesAffectedPdr', value); },
    },
    averageNumberOfEyesAffectedRvo: {
      get() { return this.$store.state.averageNumberOfEyesAffectedRvo; },
      set(value) { this.$store.commit('updateAverageNumberOfEyesAffectedRvo', value); },
    },
    averageNumberOfEyesAffectedCnv: {
      get() { return this.$store.state.averageNumberOfEyesAffectedCnv; },
      set(value) { this.$store.commit('updateAverageNumberOfEyesAffectedCnv', value); },
    },
    newTreatedRaniWamd() {
      return Math.round(
        (((this.population * (this.incidenceOfWamd / 100)) * (this.proportionOfPatientsDrugTreatedWamd / 100))
        * (this.proportionOfDrugTreatedPatientsReceivingRanibizumabWamd / 100)),
      );
    },
    currentTreatedRaniWamd() {
      return Math.round(
        (((this.population * (this.prevalenceOfWamd / 100)) * (this.proportionOfPatientsDrugTreatedWamd / 100))
        * (this.proportionOfDrugTreatedPatientsReceivingRanibizumabWamd / 100)),
      );
    },
    newTreatedRaniDme() {
      return Math.round(
        (((this.population * (this.incidenceOfDme / 100)) * (this.proportionOfPatientsDrugTreatedDme / 100))
        * (this.proportionOfDrugTreatedPatientsReceivingRanibizumabDme / 100)),
      );
    },
    currentTreatedRaniDme() {
      return Math.round(
        (((this.population * (this.prevalenceOfDme / 100)) * (this.proportionOfPatientsDrugTreatedDme / 100))
        * (this.proportionOfDrugTreatedPatientsReceivingRanibizumabDme / 100)),
      );
    },
    newTreatedRaniPdr() {
      return Math.round(
        (((this.population * (this.incidenceOfPdr / 100)) * (this.proportionOfPatientsDrugTreatedPdr / 100))
        * (this.proportionOfDrugTreatedPatientsReceivingRanibizumabPdr / 100)),
      );
    },
    currentTreatedRaniPdr() {
      return Math.round(
        (((this.population * (this.prevalenceOfPdr / 100)) * (this.proportionOfPatientsDrugTreatedPdr / 100))
        * (this.proportionOfDrugTreatedPatientsReceivingRanibizumabPdr / 100)),
      );
    },
    newTreatedRaniRvo() {
      return Math.round(
        (((this.population * (this.incidenceOfRvo / 100)) * (this.proportionOfPatientsDrugTreatedRvo / 100))
        * (this.proportionOfDrugTreatedPatientsReceivingRanibizumabRvo / 100)),
      );
    },
    currentTreatedRaniRvo() {
      return Math.round(
        (((this.population * (this.prevalenceOfRvo / 100)) * (this.proportionOfPatientsDrugTreatedRvo / 100))
        * (this.proportionOfDrugTreatedPatientsReceivingRanibizumabRvo / 100)),
      );
    },
    newTreatedRaniCnv() {
      return Math.round(
        (((this.population * (this.incidenceOfCnv / 100)) * (this.proportionOfPatientsDrugTreatedCnv / 100))
        * (this.proportionOfDrugTreatedPatientsReceivingRanibizumabCnv / 100)),
      );
    },
    currentTreatedRaniCnv() {
      return Math.round(
        (((this.population * (this.prevalenceOfCnv / 100)) * (this.proportionOfPatientsDrugTreatedCnv / 100))
        * (this.proportionOfDrugTreatedPatientsReceivingRanibizumabCnv / 100)),
      );
    },
  },
  methods: {
    goToShare() {
      if (this.newTreatedRaniWamdOverride) {
        this.$store.commit('setNewTreatedRaniWamd', this.newTreatedRaniWamdOverride);
      } else {
        this.$store.commit('setNewTreatedRaniWamd', this.newTreatedRaniWamd);
      }
      if (this.currentTreatedRaniWamdOverride) {
        this.$store.commit('setCurrentTreatedRaniWamd', this.currentTreatedRaniWamdOverride);
      } else {
        this.$store.commit('setCurrentTreatedRaniWamd', this.currentTreatedRaniWamd);
      }
      if (this.newTreatedRaniDmeOverride) {
        this.$store.commit('setNewTreatedRaniDme', this.newTreatedRaniDmeOverride);
      } else {
        this.$store.commit('setNewTreatedRaniDme', this.newTreatedRaniDme);
      }
      if (this.currentTreatedRaniDmeOverride) {
        this.$store.commit('setCurrentTreatedRaniDme', this.currentTreatedRaniDmeOverride);
      } else {
        this.$store.commit('setCurrentTreatedRaniDme', this.currentTreatedRaniDme);
      }
      if (this.newTreatedRaniPdrOverride) {
        this.$store.commit('setNewTreatedRaniPdr', this.newTreatedRaniPdrOverride);
      } else {
        this.$store.commit('setNewTreatedRaniPdr', this.newTreatedRaniPdr);
      }
      if (this.currentTreatedRaniPdrOverride) {
        this.$store.commit('setCurrentTreatedRaniPdr', this.currentTreatedRaniPdrOverride);
      } else {
        this.$store.commit('setCurrentTreatedRaniPdr', this.currentTreatedRaniPdr);
      }
      if (this.newTreatedRaniRvoOverride) {
        this.$store.commit('setNewTreatedRaniRvo', this.newTreatedRaniRvoOverride);
      } else {
        this.$store.commit('setNewTreatedRaniRvo', this.newTreatedRaniRvo);
      }
      if (this.currentTreatedRaniRvoOverride) {
        this.$store.commit('setCurrentTreatedRaniRvo', this.currentTreatedRaniRvoOverride);
      } else {
        this.$store.commit('setCurrentTreatedRaniRvo', this.currentTreatedRaniRvo);
      }
      if (this.newTreatedRaniCnvOverride) {
        this.$store.commit('setNewTreatedRaniCnv', this.newTreatedRaniCnvOverride);
      } else {
        this.$store.commit('setNewTreatedRaniCnv', this.newTreatedRaniCnv);
      }
      if (this.currentTreatedRaniCnvOverride) {
        this.$store.commit('setCurrentTreatedRaniCnv', this.currentTreatedRaniCnvOverride);
      } else {
        this.$store.commit('setCurrentTreatedRaniCnv', this.currentTreatedRaniCnv);
      }
      if (this.patientTypes.includes('new')) {
        this.$router.push('/share');
      } else {
        this.$router.push('/share-switch');
      }
    },
    selectIndication(indication) {
      this.selectedIndication = indication;
    },
    sendPage() {
      this.$store.commit('setPage', 'patient-numbers');
    },
    checkHashExists() {
      if (this.hash.length === 0) {
        this.$router.push('/inactive-link');
      }
    },
    setIndication() {
      // eslint-disable-next-line
      this.selectedIndication = this.indications[0];
    },
  },
  mounted() {
    this.checkHashExists();
    this.sendPage();
    this.setIndication();
  },
};
</script>
